.carrousel {
  display: block;
  height: 100%;
  width: 100%;

  &__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    padding-bottom: 10%;
    height: 100%;
    width: 100%;
  }

  &--arrows {
    max-height: 750px;
  }
}

@media screen and (min-width: $lg) {
  .carrousel {
    &__image {
      padding-bottom: 0;
    }
  }
}
