.content--simple {
  padding: 80px 5%;

  .box {
    display: block;
  }

  .title {
    font-size: 2.4rem;
    line-height: 30px;
    font-weight: 500;
  }

  .text {
    font-size: 1.6rem;
    line-height: 2.6rem;
    letter-spacing: 1px;
    margin-top: 10px;
  }

  .link {
    color: currentColor;
    font-family: $serif;
    margin-top: 35px;
  }
}