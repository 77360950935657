//color
$blue: #1a283c;
$grey: #f4f4f4;
$darkgrey: #848484;
$white: #fff;

$serif : 'Raleway', sans-serif;
$sans-serif : 'Montserrat', sans-serif;

// responsive

$sm: 576px;

// Medium devices (tablets, 768px and up)
$md: 768px;

// Large devices (desktops, 992px and up)
$lg : 992px;

// Extra large devices (large desktops, 1200px and up)
$xl: 1200px;