.menu {
  align-items: stretch;
  background-color: $white;
  color: currentColor;
  display: flex;
  flex-direction: column;
  height: 100%;
  list-style: none;

  &__logo {
    background-color: currentColor;
    padding: 10px 25px;
    width: 40px;
  }

  &__content {
    background-color: $white;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    height: calc(100vh - 60px);
    justify-content: space-between;
    left: 0;
    position: fixed;
    top: 60px;
    transition: transform 0.3s;
    transform: translateX(100%);
    width: 100%;
    z-index: 10;

    .is-opened & {
      transform: translateX(0);
    }

    .admin-bar & {
      height: calc(100vh - 134px);
      top: 134px;
    }
  }

  &__right {
    width: 100%;
  }

  &__estimation {
    background-color: $blue;
    padding: 20px;

    &__text {
      display: block;
      padding-bottom: 5px;
      text-transform: uppercase;
    }

    &__phone,
    &__text {
      color: $white;
      font-size: 1.2rem;
      text-decoration: none;
    }
  }

  &__burger {
    cursor: pointer;
    height: 100%;
    position: absolute;
    right: 0;
    width: 90px;

    .is-opened & {
      background-color: $grey;
    }
  }

  .footer & {
    background-color: transparent;
    text-align: center;
  }
}

.menu-container {
  align-items: stretch;
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 60px;
  position: relative;
}

/* WP Menu Class*/

.menu-item {
  align-items: center;
  display: flex;

  &:hover {
    background-color: $grey;

    .footer & {
      background-color: transparent;
      color: $grey;

      > a {
        color: $grey;
      }
    }
  }

  &.current-menu-item {
    background-color: $grey;

    .footer & {
      background-color: transparent;
    }
  }

  > a {
    align-items: center;
    color: currentColor;
    display: flex;
    height: 100%;
    font-family: $serif;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 1px;
    padding: 20px;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;

    .footer & {
      display: inline-block;
      padding: 0;
      text-transform: capitalize;
      width: auto;
    }
  }

  .footer & {
    display: inline-block;
    text-align: center;

    + .menu-item {
      margin-top: 10px;
    }
  }
}

.sub-menu {
  background-color: $white;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  display: none;
  z-index: 5;

  .menu-item {
    background-color: $white;
    padding-right: 40px;
    transition: background-color 0.6s ease;

    &:hover {
      background-color: $grey;
    }
  }
}

@media screen and (min-width: $md) {
  .menu-item {
    .footer & {
      text-align: left;
    }
  }
}

@media screen and (min-width: $lg) {
  .menu {
    flex-direction: row;

    &__logo {
      align-self: center;
      width: 70px;
    }

    &__content {
      height: auto;
      flex-direction: row;
      margin-left: 5%;
      position: relative;
      transform: translate(0);
      top: inherit;

      .footer & {
        margin-left: 0;
      }

      .admin-bar & {
        height: auto;
        top: 0;
      }
    }

    &__right {
      align-items: center;
      align-self: center;
      display: flex;
      width: auto;
    }

    &__estimation {
      background-color: $white;
      padding: 0;
      text-align: right;

      &__text,
      &__phone {
        color: $darkgrey;
        transition: color 0.3s;

        &:hover {
          color: $blue;
        }
      }
    }

    &__burger {
      display: none;
    }

    .footer & {
      flex-direction: column;
    }
  }

  .menu-container {
    flex-direction: row;
    height: 88px;
  }

  .menu-item {
    padding: 0;

    &-has-children {
      position: relative;

      &:hover {
        .sub-menu {
          bottom: 0;
          display: block;
          left: 0;
          position: absolute;
          transform: translate(0, 100%);
        }
      }
    }
  }
}

@media screen and (min-width: $xl) {
  .menu-item > a {
    font-size: 1.4rem;
  }

  .menu {
    &__estimation {
      &__phone,
      &__text {
        font-size: 1.4rem;
      }
    }
  }
}
