.footer {
  align-items: center;
  color: $white;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  flex-wrap: wrap;
  padding: 90px 35px;

  &__item {
    align-items: center;
    display: flex;

    + .footer__item {
        margin-top: 40px;
    }
  }

  &__copy {
    flex-direction: column;
    justify-content: space-between;
  }

  &__logo {
    margin-bottom: 20px;
    width: 60px;
  }

  &__follow {
    align-items: center;
    display: flex;
    flex-direction: column;

    &__text {
      font-size: 1.4rem;
    }

    &__icons {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 20px;
    }

    &__icon {
      display: block;
      font-size: 20px;
      height: 25px;
      position: relative;
      width: 25px;

      + .footer__follow__icon {
        margin-left: 20px;
      }
    }
  }

  [link] {
    color: $white;
    font-size: 2rem;
    height: 20px;
    width: 2px;
  }

  [link*="facebook"] {
    font-family: "Font Awesome 5 Brands";

    &::before {
      content: "\f39e";
      display: block;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
    }
  }

  [link*="twitter"] {
    font-family: "Font Awesome 5 Brands";

    &::before {
      content: "\f099";
      display: block;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
    }
  }

  [link*="instagram"] {
    font-family: "Font Awesome 5 Brands";

    &::before {
      content: "\f16d";
      display: block;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
    }
  }

  [link*="linkedin"] {
    font-family: "Font Awesome 5 Brands";

    &::before {
      content: "\f0e1";
      display: block;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
    }
  }

  [link*="pinterest"] {
    font-family: "Font Awesome 5 Brands";

    &::before {
      content: "\f0d2";
      display: block;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
    }
  }
}

@media screen and (min-width: $md) {
  .footer {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;

    &__item {
      align-items: flex-start;
      text-align: left;

      + .footer__item {
        margin-top: 0;
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
  }
}