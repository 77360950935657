.team-box {
    align-items: center;
    color: currentColor;
    display: none;
    height: 100%;
    justify-content: center;
}

.team {
    margin-right: 20px;
    margin-left: 20px;
    text-align: center;

    &__image {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        display: block;
        overflow: hidden;
        width: 175px;
        height: 175px;
        margin-right: auto;
        margin-bottom: 40px;
        margin-left: auto;
        border-radius: 150px;
    }

    &__name {
        margin-bottom: 10px;
        font-size: 26px;
        font-family: $serif;
        font-weight: 500;
        text-align: center;
    }

    &__title {
        font-family: $serif;
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (min-width: $lg) {
    .team-box {
        display: flex;
    }
}