* {
  margin: 0;
  padding: 0;  
}

body, html {
  font-family: $sans-serif;
  font-size: 10px;
  font-weight: 500;
  overflow-x: hidden;
  width: 100%;
}

.read-more {
  color: currentColor;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  padding-bottom: 4px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 0;
    transition: width 0s ease, background .5s ease;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 3px;
    width: 0;
    background: currentColor;
    transition: width .5s ease;
  }

  &:hover {
    &::before {
      background: currentColor;
      transition: width .5s ease;
      width: 100%;
    }

    &::after {
      background: transparent;
      transition: all 0s ease;
      width: 100%
    }
  }
}