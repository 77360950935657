.team-member {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__section-title {
    font-family: $sans-serif;
    font-size: 3rem;
    font-weight: 500;
    padding: 80px 35px;
  }

  &__content {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 0 0 80%;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;

    &:hover {
      .team-member__text {
        transform: translate(0, 0);
      }
    }
  }

  &__text {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    height: 100%;
    justify-content: center;
    line-height: 1.85;
    padding: 0 35px;
    position: absolute;
    text-align: center;
    transition: transform 0.3s ease;
    transform: translate(0, 100%);
  }

  &__info {
    display: flex;
    flex: 1 1 20%;
    flex-direction: column;
    justify-content: center;
    padding: 40px 20px;
    text-align: center;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__name {
    font-size: 2.4rem;

    +.team-member__title {
      margin-top: 10px;
    }
  }

  &__title {
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  &__presentation {
    display: flex;
    flex: 0 0 80%;
    flex-direction: column;
    font-size: 1.6rem;
    justify-content: center;
    line-height: 1.625;
    padding: 60px 35px;
    text-align: center;
  }
}

@media screen and (min-width: $md) {
  .team-member {
    &__content {
      min-height: 500px;
      padding-bottom: 0;
    }
  }
}

@media screen and (min-width: $lg) {
  .team-member {
    &__presentation {
      padding: 0 35px;
    }
  }
}