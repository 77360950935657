.flex-row {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &--reverse {
        flex-direction: column-reverse;
    }

    &--blue {
        color: #1a283c;
    }

    &--white {
        color: #fff;
    }
}

@media screen and (min-width: $lg) {
    .flex-row {
        flex-direction: row;

        &--reverse {
            flex-direction: row;
        }
    }
}