.text-box {
    color: currentColor;
    padding: 90px 35px;

    &__title {
        font-family: $sans-serif;
        font-size: 2.6rem;
        font-weight: 500;
        line-height: 34px;
        margin-top: 35px;
        margin-bottom: 35px;

        > * {
            font-family: $sans-serif;
            font-size: 2.6rem;
            font-weight: 500;
        }

        > h1 {
            font-size: 3rem;
        }
    }

    &__subtitle {
        font-family: $serif;
        font-size: 1.2rem;
        line-height: 17.5px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &__text {
        color: currentColor;
        font-family: $sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 26px;
        
        > p {
            color: currentColor;
            font-family: $serif;
            font-size: 1.6rem;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 26px;
        }

        em,
        strong,
        li {
            color: currentColor;
            font-family: $serif;
            font-size: 1.6rem;
        }

        em {
            display: inline-block;
            margin: 20px 0 10px;
        }

        strong {
            font-weight: bold;
        }

    }

    &__list {
        margin-top: 20px;
    }

    &__link {
        color: currentColor;
        font-family: $serif;
        margin-top: 35px;
    }
}

@media screen and (min-width: $lg) {
    .text-box {
        padding: 90px 85px;
    }
}