* {
  margin: 0;
  padding: 0; }

body, html {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 500;
  overflow-x: hidden;
  width: 100%; }

.read-more {
  color: currentColor;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  padding-bottom: 4px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase; }
  .read-more::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 0;
    transition: width 0s ease, background .5s ease; }
  .read-more::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 3px;
    width: 0;
    background: currentColor;
    transition: width .5s ease; }
  .read-more:hover::before {
    background: currentColor;
    transition: width .5s ease;
    width: 100%; }
  .read-more:hover::after {
    background: transparent;
    transition: all 0s ease;
    width: 100%; }

.button {
  position: relative; }
  .button--file {
    color: #fff;
    display: inline-block;
    font-family: "Raleway", sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 10px;
    padding: 10px 45px 10px 10px;
    text-decoration: none; }
  .button__icons {
    display: block;
    height: 22px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px; }
  .button__arrow, .button__bar {
    font-size: 1rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%); }
  .button__arrow {
    font-size: 2rem;
    top: 0px;
    transition: top 0.3s; }
  .button__bar {
    bottom: 0; }
  .button:hover .button__arrow {
    top: 3px; }

.flex-row {
  display: flex;
  flex-direction: column;
  overflow: hidden; }
  .flex-row--reverse {
    flex-direction: column-reverse; }
  .flex-row--blue {
    color: #1a283c; }
  .flex-row--white {
    color: #fff; }

@media screen and (min-width: 992px) {
  .flex-row {
    flex-direction: row; }
    .flex-row--reverse {
      flex-direction: row; } }

.flex-vertical {
  display: flex;
  flex-direction: column; }

.flex-col {
  flex: 0 0 100%; }
  .flex-col--1 {
    flex: 0 0 8.3333%;
    max-width: 8.3333%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--3 {
    flex: 0 0 25%;
    max-width: 25%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--6 {
    flex: 0 0 50%;
    max-width: 50%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--7 {
    flex: 0 0 58.333%;
    max-width: 58.333%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--9 {
    flex: 0 0 75%;
    max-width: 75%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--10 {
    flex: 0 0 83.333%;
    max-width: 83.333%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--12 {
    flex: 0 0 100%;
    max-width: 100%;
    min-height: 1px;
    position: relative;
    width: 100%; }

@media screen and (min-width: 992px) {
  .flex-col--1 {
    flex: 0 0 8.3333%;
    max-width: 8.3333%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--3 {
    flex: 0 0 25%;
    max-width: 25%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--6 {
    flex: 0 0 50%;
    max-width: 50%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--7 {
    flex: 0 0 58.333%;
    max-width: 58.333%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--9 {
    flex: 0 0 75%;
    max-width: 75%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--10 {
    flex: 0 0 83.333%;
    max-width: 83.333%;
    min-height: 1px;
    position: relative;
    width: 100%; }
  .flex-col--11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
    min-height: 1px;
    position: relative;
    width: 100%; } }

.footer {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  flex-wrap: wrap;
  padding: 90px 35px; }
  .footer__item {
    align-items: center;
    display: flex; }
    .footer__item + .footer__item {
      margin-top: 40px; }
  .footer__copy {
    flex-direction: column;
    justify-content: space-between; }
  .footer__logo {
    margin-bottom: 20px;
    width: 60px; }
  .footer__follow {
    align-items: center;
    display: flex;
    flex-direction: column; }
    .footer__follow__text {
      font-size: 1.4rem; }
    .footer__follow__icons {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 20px; }
    .footer__follow__icon {
      display: block;
      font-size: 20px;
      height: 25px;
      position: relative;
      width: 25px; }
      .footer__follow__icon + .footer__follow__icon {
        margin-left: 20px; }
  .footer [link] {
    color: #fff;
    font-size: 2rem;
    height: 20px;
    width: 2px; }
  .footer [link*="facebook"] {
    font-family: "Font Awesome 5 Brands"; }
    .footer [link*="facebook"]::before {
      content: "\f39e";
      display: block;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%; }
  .footer [link*="twitter"] {
    font-family: "Font Awesome 5 Brands"; }
    .footer [link*="twitter"]::before {
      content: "\f099";
      display: block;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%; }
  .footer [link*="instagram"] {
    font-family: "Font Awesome 5 Brands"; }
    .footer [link*="instagram"]::before {
      content: "\f16d";
      display: block;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%; }
  .footer [link*="linkedin"] {
    font-family: "Font Awesome 5 Brands"; }
    .footer [link*="linkedin"]::before {
      content: "\f0e1";
      display: block;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%; }
  .footer [link*="pinterest"] {
    font-family: "Font Awesome 5 Brands"; }
    .footer [link*="pinterest"]::before {
      content: "\f0d2";
      display: block;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%; }

@media screen and (min-width: 768px) {
  .footer {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between; }
    .footer__item {
      align-items: flex-start;
      text-align: left; }
      .footer__item + .footer__item {
        margin-top: 0; } }

@media screen and (min-width: 992px) {
  .footer {
    flex-direction: row;
    justify-content: space-between; } }

.wpml-ls {
  background-color: #fff;
  border: 0 !important;
  padding: 20px !important; }

.wpml-ls-current-language {
  display: none !important; }

.wpml-ls-display {
  display: none; }

.wpml-ls-link {
  text-transform: uppercase;
  color: #1a283c;
  font-size: 1.2rem; }

@media screen and (min-width: 992px) {
  .wpml-ls-link {
    color: #848484;
    padding: 0 0 0 20px !important;
    position: relative;
    transition: color 0.3s; }
    .wpml-ls-link::before {
      background-color: #848484;
      content: '';
      left: 0;
      height: 11px;
      position: absolute;
      top: calc(50% + 1px);
      transform: translateY(-50%);
      width: 1px; }
    .wpml-ls-link:hover {
      color: #1a283c; } }

@media screen and (min-width: 1200px) {
  .wpml-ls-native {
    font-size: 1.4rem; } }

.burger {
  color: #1a283c;
  font-size: 50px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%); }

.carrousel {
  display: block;
  height: 100%;
  width: 100%; }
  .carrousel__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    padding-bottom: 10%;
    height: 100%;
    width: 100%; }
  .carrousel--arrows {
    max-height: 750px; }

@media screen and (min-width: 992px) {
  .carrousel__image {
    padding-bottom: 0; } }

.map {
  padding-bottom: 100%; }
  .map-container .map {
    height: 100%;
    left: 0;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.map-container {
  display: block;
  position: relative;
  width: 100%; }
  .map-container::before {
    content: '';
    display: block;
    padding-bottom: 100%; }

@media screen and (min-width: 576px) {
  .map {
    padding-bottom: 80%; }
  .map-container::before {
    padding-bottom: 80%; } }

@media screen and (min-width: 768px) {
  .map {
    padding-bottom: 60%; }
  .map-container::before {
    padding-bottom: 60%; } }

@media screen and (min-width: 992px) {
  .map {
    height: 100%;
    padding-bottom: 0; }
  .map-container::before {
    padding-bottom: 40%; } }

.menu {
  align-items: stretch;
  background-color: #fff;
  color: currentColor;
  display: flex;
  flex-direction: column;
  height: 100%;
  list-style: none; }
  .menu__logo {
    background-color: currentColor;
    padding: 10px 25px;
    width: 40px; }
  .menu__content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    height: calc(100vh - 60px);
    justify-content: space-between;
    left: 0;
    position: fixed;
    top: 60px;
    transition: transform 0.3s;
    transform: translateX(100%);
    width: 100%;
    z-index: 10; }
    .is-opened .menu__content {
      transform: translateX(0); }
    .admin-bar .menu__content {
      height: calc(100vh - 134px);
      top: 134px; }
  .menu__right {
    width: 100%; }
  .menu__estimation {
    background-color: #1a283c;
    padding: 20px; }
    .menu__estimation__text {
      display: block;
      padding-bottom: 5px;
      text-transform: uppercase; }
    .menu__estimation__phone, .menu__estimation__text {
      color: #fff;
      font-size: 1.2rem;
      text-decoration: none; }
  .menu__burger {
    cursor: pointer;
    height: 100%;
    position: absolute;
    right: 0;
    width: 90px; }
    .is-opened .menu__burger {
      background-color: #f4f4f4; }
  .footer .menu {
    background-color: transparent;
    text-align: center; }

.menu-container {
  align-items: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 60px;
  position: relative; }

/* WP Menu Class*/
.menu-item {
  align-items: center;
  display: flex; }
  .menu-item:hover {
    background-color: #f4f4f4; }
    .footer .menu-item:hover {
      background-color: transparent;
      color: #f4f4f4; }
      .footer .menu-item:hover > a {
        color: #f4f4f4; }
  .menu-item.current-menu-item {
    background-color: #f4f4f4; }
    .footer .menu-item.current-menu-item {
      background-color: transparent; }
  .menu-item > a {
    align-items: center;
    color: currentColor;
    display: flex;
    height: 100%;
    font-family: "Raleway", sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 1px;
    padding: 20px;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%; }
    .footer .menu-item > a {
      display: inline-block;
      padding: 0;
      text-transform: capitalize;
      width: auto; }
  .footer .menu-item {
    display: inline-block;
    text-align: center; }
    .footer .menu-item + .menu-item {
      margin-top: 10px; }

.sub-menu {
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  display: none;
  z-index: 5; }
  .sub-menu .menu-item {
    background-color: #fff;
    padding-right: 40px;
    transition: background-color 0.6s ease; }
    .sub-menu .menu-item:hover {
      background-color: #f4f4f4; }

@media screen and (min-width: 768px) {
  .footer .menu-item {
    text-align: left; } }

@media screen and (min-width: 992px) {
  .menu {
    flex-direction: row; }
    .menu__logo {
      align-self: center;
      width: 70px; }
    .menu__content {
      height: auto;
      flex-direction: row;
      margin-left: 5%;
      position: relative;
      transform: translate(0);
      top: inherit; }
      .footer .menu__content {
        margin-left: 0; }
      .admin-bar .menu__content {
        height: auto;
        top: 0; }
    .menu__right {
      align-items: center;
      align-self: center;
      display: flex;
      width: auto; }
    .menu__estimation {
      background-color: #fff;
      padding: 0;
      text-align: right; }
      .menu__estimation__text, .menu__estimation__phone {
        color: #848484;
        transition: color 0.3s; }
        .menu__estimation__text:hover, .menu__estimation__phone:hover {
          color: #1a283c; }
    .menu__burger {
      display: none; }
    .footer .menu {
      flex-direction: column; }
  .menu-container {
    flex-direction: row;
    height: 88px; }
  .menu-item {
    padding: 0; }
    .menu-item-has-children {
      position: relative; }
      .menu-item-has-children:hover .sub-menu {
        bottom: 0;
        display: block;
        left: 0;
        position: absolute;
        transform: translate(0, 100%); } }

@media screen and (min-width: 1200px) {
  .menu-item > a {
    font-size: 1.4rem; }
  .menu__estimation__phone, .menu__estimation__text {
    font-size: 1.4rem; } }

.image-box {
  background-position: center center;
  background-size: cover;
  height: 500px;
  width: 100%; }
  .image-box--in-first-row .image-box {
    display: none; }

@media screen and (min-width: 992px) {
  .image-box {
    height: 100%; }
    .image-box--in-first-row .image-box {
      display: block; } }

.portfolio {
  margin: 0 auto;
  padding: 75px 5%; }

.text-box {
  color: currentColor;
  padding: 90px 35px; }
  .text-box__title {
    font-family: "Montserrat", sans-serif;
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 34px;
    margin-top: 35px;
    margin-bottom: 35px; }
    .text-box__title > * {
      font-family: "Montserrat", sans-serif;
      font-size: 2.6rem;
      font-weight: 500; }
    .text-box__title > h1 {
      font-size: 3rem; }
  .text-box__subtitle {
    font-family: "Raleway", sans-serif;
    font-size: 1.2rem;
    line-height: 17.5px;
    font-weight: 700;
    text-transform: uppercase; }
  .text-box__text {
    color: currentColor;
    font-family: "Montserrat", sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 26px; }
    .text-box__text > p {
      color: currentColor;
      font-family: "Raleway", sans-serif;
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 26px; }
    .text-box__text em,
    .text-box__text strong,
    .text-box__text li {
      color: currentColor;
      font-family: "Raleway", sans-serif;
      font-size: 1.6rem; }
    .text-box__text em {
      display: inline-block;
      margin: 20px 0 10px; }
    .text-box__text strong {
      font-weight: bold; }
  .text-box__list {
    margin-top: 20px; }
  .text-box__link {
    color: currentColor;
    font-family: "Raleway", sans-serif;
    margin-top: 35px; }

@media screen and (min-width: 992px) {
  .text-box {
    padding: 90px 85px; } }

.team-member {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .team-member__section-title {
    font-family: "Montserrat", sans-serif;
    font-size: 3rem;
    font-weight: 500;
    padding: 80px 35px; }
  .team-member__content {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 0 0 80%;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative; }
    .team-member__content:hover .team-member__text {
      transform: translate(0, 0); }
  .team-member__text {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    height: 100%;
    justify-content: center;
    line-height: 1.85;
    padding: 0 35px;
    position: absolute;
    text-align: center;
    transition: transform 0.3s ease;
    transform: translate(0, 100%); }
  .team-member__info {
    display: flex;
    flex: 1 1 20%;
    flex-direction: column;
    justify-content: center;
    padding: 40px 20px;
    text-align: center;
    text-decoration: none; }
    .team-member__info:hover {
      text-decoration: none; }
  .team-member__name {
    font-size: 2.4rem; }
    .team-member__name + .team-member__title {
      margin-top: 10px; }
  .team-member__title {
    font-size: 1.2rem;
    text-transform: uppercase; }
  .team-member__presentation {
    display: flex;
    flex: 0 0 80%;
    flex-direction: column;
    font-size: 1.6rem;
    justify-content: center;
    line-height: 1.625;
    padding: 60px 35px;
    text-align: center; }

@media screen and (min-width: 768px) {
  .team-member__content {
    min-height: 500px;
    padding-bottom: 0; } }

@media screen and (min-width: 992px) {
  .team-member__presentation {
    padding: 0 35px; } }

.content--simple {
  padding: 80px 5%; }
  .content--simple .box {
    display: block; }
  .content--simple .title {
    font-size: 2.4rem;
    line-height: 30px;
    font-weight: 500; }
  .content--simple .text {
    font-size: 1.6rem;
    line-height: 2.6rem;
    letter-spacing: 1px;
    margin-top: 10px; }
  .content--simple .link {
    color: currentColor;
    font-family: "Raleway", sans-serif;
    margin-top: 35px; }

.contact__zone {
  padding: 50px 15px; }

.contact__title {
  color: currentColor;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4rem; }

.contact__text {
  color: currentColor;
  font-family: "Raleway", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.6rem;
  letter-spacing: 1px;
  margin-top: 40px; }
  .contact__text > * {
    color: currentColor;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.6rem;
    letter-spacing: 1px;
    margin: 0; }

.contact__details {
  border-top: 1px solid currentColor;
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  padding: 35px 0 0; }

.contact__detail {
  letter-spacing: 1px;
  font-family: "Raleway", sans-serif;
  margin-top: 20px; }
  .contact__detail__info {
    margin-top: 20px; }
    .contact__detail__info + .contact__info {
      margin-top: 10px; }
  .contact__detail__title {
    color: currentColor;
    font-size: 2.6rem;
    font-weight: 500; }
  .contact__detail__name {
    font-size: 1.6rem; }
  .contact__detail__link {
    color: currentColor;
    font-family: "Raleway", sans-serif;
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none; }
    .contact__detail__link:hover {
      text-decoration: none; }
  .contact__detail + .contact__detail {
    margin-top: 50px; }

.contact__form__text {
  color: currentColor;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  margin: 20px 0;
  text-decoration: none; }

[data-class=wpcf7cf_group] {
  margin-top: 20px; }

@media screen and (min-width: 768px) {
  .contact__details {
    align-items: flex-start;
    flex-direction: row; }
  .contact__detail {
    margin-top: 0; }
    .contact__detail + .contact__detail {
      margin-top: 0;
      padding-left: 50px; } }

@media screen and (min-width: 992px) {
  .contact__zone {
    padding: 80px 60px; }
  .contact__title {
    font-size: 3.5rem; }
  .contact__detail + .contact__detail {
    padding-left: 100px; } }

.form {
  box-sizing: border-box;
  color: #1a283c;
  font-family: "Raleway", sans-serif;
  margin-top: 40px; }
  .form p,
  .form label,
  .form input,
  .form textarea,
  .form select {
    width: 100%; }
  .form p {
    display: block;
    font-size: 1.6rem;
    margin-bottom: 5px; }
  .form input,
  .form select {
    box-sizing: border-box;
    display: block;
    height: 45px;
    margin-bottom: 10px; }
  .form input,
  .form textarea,
  .form select {
    border: 2px solid #f4f4f4;
    color: currentColor;
    font-size: 1.4rem;
    font-weight: 400;
    outline: none;
    line-height: 20px;
    padding: 8px 12px;
    transition: border-color 0.3s; }
    .form input:focus,
    .form textarea:focus,
    .form select:focus {
      border-color: currentColor;
      outline: none; }
    .form input.wpcf7-not-valid,
    .form textarea.wpcf7-not-valid,
    .form select.wpcf7-not-valid {
      border-color: red; }
  .form select {
    box-sizing: border-box;
    display: block;
    height: 45px;
    margin-bottom: 10px; }
  .form [type=submit] {
    background-color: #1a283c;
    border: 2px solid #1a283c;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 1.6rem;
    font-weight: 400;
    height: auto;
    margin-top: 20px;
    padding: 15px 65px;
    transition: background-color 0.3s, color 0.3s;
    width: auto; }
    .form [type=submit]:hover {
      background-color: transparent;
      color: #1a283c; }

.wpcf7-not-valid-tip {
  display: none !important; }

.wpcf7-response-output {
  font-size: 1.6rem !important; }

.hero__content {
  padding: 220px 5% 210px;
  position: relative; }

.hero__logo {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  bottom: 0;
  display: block;
  max-width: 1031px;
  opacity: 0.1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  z-index: 1; }

.hero__title {
  font-family: "Montserrat", sans-serif;
  font-size: 4.5rem;
  font-weight: 500;
  max-width: 800px; }

.hero__subtitle,
.hero__subtitle > p {
  font-size: 1.6rem;
  margin-top: 55px; }

@media screen and (min-width: 768px) {
  .hero__title {
    font-size: 6.5rem; } }

@media screen and (min-width: 992px) {
  .hero__logo {
    left: 100%; } }

.team-box {
  align-items: center;
  color: currentColor;
  display: none;
  height: 100%;
  justify-content: center; }

.team {
  margin-right: 20px;
  margin-left: 20px;
  text-align: center; }
  .team__image {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: block;
    overflow: hidden;
    width: 175px;
    height: 175px;
    margin-right: auto;
    margin-bottom: 40px;
    margin-left: auto;
    border-radius: 150px; }
  .team__name {
    margin-bottom: 10px;
    font-size: 26px;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    text-align: center; }
  .team__title {
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    line-height: 20px; }

@media screen and (min-width: 992px) {
  .team-box {
    display: flex; } }

.list + .button-container,
.list + .list {
  margin-top: 40px; }

.list__title {
  font-family: "Raleway", sans-serif;
  font-size: 1.6rem;
  font-style: italic;
  font-weight: bold; }

.list ul {
  color: currentColor;
  margin-top: 10px;
  padding-left: 15px; }

.list li {
  font-family: "Raleway", sans-serif;
  font-size: 1.6rem;
  margin-top: 10px; }

@media screen and (min-width: 992px) {
  .list__items--two {
    display: flex;
    justify-content: space-between; }
    .list__items--two ul {
      flex: 1 1 50%;
      padding-right: 15px; } }

.slick-list {
  display: block;
  height: 100%;
  width: 100%; }

.slick-track {
  display: block;
  height: 100%; }

[data-module="to-top"] {
  cursor: pointer;
  font-size: 1.2rem;
  text-transform: uppercase; }

[data-module="inview"] {
  opacity: 1;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition: opacity 600ms ease 0s, transform 600ms ease 0s; }
  [data-module="inview"].inview--not {
    opacity: 0;
    transform: translate(0, -40px); }
    [data-module="inview"].inview--not.image-box {
      opacity: 0;
      transform: translate(0, 0); }
  [data-module="inview"].inview--1 {
    transition-delay: 300ms; }
  [data-module="inview"].inview--2 {
    transition-delay: 600ms; }
  [data-module="inview"].inview--3 {
    transition-delay: 900ms; }
