.slick-list {
    display: block;
    height: 100%;
    width: 100%;
}

.slick-track {
    display: block;
    height: 100%;
}
